import NextImage from 'next/image'
import NextLink from 'next/link'
import { useRouter } from 'next/router'
import { Fragment, useContext } from 'react'

import { useToast } from '@chakra-ui/react'
import { Popover, Transition } from '@headlessui/react'
import {
  BuildingOffice2Icon,
  BuildingStorefrontIcon,
  CalendarIcon,
  CpuChipIcon,
  HomeModernIcon,
  InformationCircleIcon,
  NewspaperIcon,
  UserCircleIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/24/outline'

import api from '../api'
import { auth } from '../api/endpoint'
import AuthContext from '../stores/AuthContext'

const engagement = [
  { name: 'Home', href: '/', icon: InformationCircleIcon },
  { name: 'Book a session', href: '/locations', icon: CalendarIcon },
  { name: 'Our tech', href: '/our-tech', icon: CpuChipIcon },
  { name: 'Media', href: 'https://media.staytuned.co', icon: NewspaperIcon },
  {
    name: 'Support help line (SMS only): (254)277-4412',
    href: 'tel:(254)277-4412',
    icon: QuestionMarkCircleIcon,
  },
]
const resources = [
  { name: 'TUNE Studio', href: '/tune-studio', icon: BuildingStorefrontIcon },
  { name: 'TUNE for Work', href: '/tune-work', icon: BuildingOffice2Icon },
  { name: 'TUNE for Home', href: '/products', icon: HomeModernIcon },
]
const recentPosts = [
  {
    id: 1,
    title: `We're Just Getting Started`,
    href: 'https://media.staytuned.co/article/getting-started',
    date: 'Mar 16, 2023',
    datetime: '2023-03-16',
    category: {
      title: '',
      href: 'https://media.staytuned.co/article/getting-started',
    },
    imageUrl:
      'https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3603&q=80',
    description:
      'COVID stopped us in our tracks. TUNE was an experiential wellness company that worked within hotels, corporations and an athleisure brand, so of course all of our locations were closed.',
  },
  {
    id: 2,
    title: 'The Launch',
    href: 'https://media.staytuned.co/article/the-launch',
    date: 'Mar 10, 2023',
    datetime: '2023-03-10',
    category: {
      title: '',
      href: 'https://media.staytuned.co/article/the-launch',
    },
    imageUrl:
      'https://images.unsplash.com/photo-1547586696-ea22b4d4235d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3270&q=80',
    description:
      'Within weeks of our instrument going into production, we had Google, Lululemon White Space Labs and Marriott Luxury Group all studying and testing our TUNEs.',
  },
]

export const Menu = ({ isLoggedIn }: { isLoggedIn: boolean }) => {
  const { state, clearUserState } = useContext(AuthContext)
  const router = useRouter()
  const toast = useToast()
  const handleSignOut = async () => {
    try {
      await api.post(auth.LOGOUT_END_POINT, {
        refreshToken: state.tokens?.refresh.token,
      })
      clearUserState()
      localStorage?.removeItem('kioskMode')
      localStorage?.removeItem('adminConnectedBed')
      toast({ title: 'Signed out successfully', status: 'success' })
    } catch (err: any) {
      toast({ title: err?.response?.data?.message, status: 'error' })
      clearUserState()
    } finally {
      router.push('/signin')
    }
  }
  return (
    <>
      <div className={`transition-colors duration-150 py-5 bg-transparent`}>
        <div className="mx-auto max-w-7xl">
          <Popover.Button
            className={`inline-flex items-center gap-x-1 text-md font-bold font-scto uppercase text-secondary`}
          >
            Menu
          </Popover.Button>
        </div>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-75"
        enterFrom="opacity-0 -translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 -translate-y-1"
      >
        <Popover.Panel
          className={`absolute inset-x-0 top-0 -z-10 bg-white pt-16 shadow-lg ring-1 ring-gray-900/5 w-screen`}
        >
          <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-6 py-10 md:grid-cols-2 lg:px-8 xl:px-24">
            <div className="grid grid-cols-2 gap-x-6 sm:gap-x-8">
              <div>
                <h3 className="text-sm font-medium leading-6 text-gray-500">
                  Navigate
                </h3>
                <div className="mt-6 flow-root">
                  <div className="-my-2">
                    {engagement.map((item) => (
                      <NextLink key={item.name} href={item.href}>
                        <a className="flex gap-x-4 py-2 text-sm font-semibold leading-6 text-gray-900 font-scto">
                          <item.icon
                            className="h-6 w-6 flex-none text-gray-400"
                            aria-hidden="true"
                          />
                          {item.name}
                        </a>
                      </NextLink>
                    ))}
                    {isLoggedIn ? (
                      <button
                        className="flex gap-x-4 py-2 text-sm font-semibold leading-6 text-gray-900 font-scto"
                        onClick={handleSignOut}
                      >
                        <UserCircleIcon
                          className="h-6 w-6 flex-none text-gray-400"
                          aria-hidden="true"
                        />
                        Sign Out
                      </button>
                    ) : (
                      <NextLink href={'/signin'}>
                        <a className="flex gap-x-4 py-2 text-sm font-semibold leading-6 text-gray-900 font-scto">
                          <UserCircleIcon
                            className="h-6 w-6 flex-none text-gray-400"
                            aria-hidden="true"
                          />
                          Sign In
                        </a>
                      </NextLink>
                    )}
                  </div>
                </div>
              </div>
              <div>
                <h3 className="text-sm font-medium leading-6 text-gray-500">
                  Learn TUNE
                </h3>
                <div className="mt-6 flow-root">
                  <div className="-my-2">
                    {resources.map((item) => (
                      <NextLink key={item.name} href={item.href}>
                        <a className="flex gap-x-4 py-2 text-sm font-semibold leading-6 text-gray-900  font-scto">
                          <item.icon
                            className="h-6 w-6 flex-none text-gray-400"
                            aria-hidden="true"
                          />
                          {item.name}
                        </a>
                      </NextLink>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-1 gap-10 sm:gap-8 lg:grid-cols-2">
              <h3 className="sr-only">Recent posts</h3>
              {recentPosts.map((post) => (
                <NextLink key={post.id} href={post.href} target="_blank">
                  <a>
                    <article className="relative isolate flex max-w-2xl flex-col gap-x-8 gap-y-6 sm:flex-row sm:items-start lg:flex-col lg:items-stretch">
                      <div className="relative flex-none">
                        <div className="aspect-[2/1] w-full rounded-lg bg-gray-100 object-cover sm:aspect-[16/9] sm:h-32 lg:h-auto">
                          <NextImage
                            className="rounded-lg"
                            src={post.imageUrl}
                            alt=""
                            layout="fill"
                          />
                        </div>
                        <div className="absolute inset-0 rounded-lg ring-1 ring-inset ring-gray-900/10" />
                      </div>
                      <div>
                        <div className="flex items-center gap-x-4">
                          <time
                            dateTime={post.datetime}
                            className="text-sm leading-6 text-gray-600"
                          >
                            {post.date}
                          </time>
                          <NextLink href={post.category.href} target="_blank">
                            <a className="relative z-10 rounded-full bg-gray-50 px-3 py-1.5 text-xs font-medium text-gray-600 hover:bg-gray-100">
                              {post.category.title}
                            </a>
                          </NextLink>
                        </div>
                        <h4 className="mt-2 text-sm font-semibold leading-6 text-gray-900">
                          <span className="absolute inset-0" />
                          {post.title}
                        </h4>
                        <p className="mt-2 text-sm leading-6 text-gray-600">
                          {post.description}
                        </p>
                      </div>
                    </article>
                  </a>
                </NextLink>
              ))}
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </>
  )
}
