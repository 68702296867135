import NextImage from 'next/image'
import NextLink from 'next/link'
import { useRouter } from 'next/router'
import { useContext, useEffect, useState } from 'react'

import { Popover } from '@headlessui/react'

import { Menu } from '../components/Menu'
import AuthContext from '../stores/AuthContext'

type NavbarProps = {
  hideMenu?: boolean
}

// Map urls to custom CTA labels
const ctaMap: { [key: string]: string } = {
  '/tune-work': 'Contact Sales',
  '/tune-studio': 'Apply',
}
const linkMap: { [key: string]: string } = {
  '/tune-work': 'mailto:hello@tune.studio',
  '/tune-studio': 'mailto:hello@tune.studio',
}

const Navbar = ({ hideMenu = false }: NavbarProps) => {
  const router = useRouter()
  const { state } = useContext(AuthContext)
  const isLoggedIn = state?.user ? true : false
  const kioskMode = state?.adminKioskMode
  const [isScrolled, setIsScrolled] = useState(false)
  // Get current path from next router
  const path = router.pathname
  const pathHasCustomCTA = Object.keys(ctaMap).includes(path)
  const customCta = pathHasCustomCTA ? ctaMap[path] : undefined
  const customLink = pathHasCustomCTA ? linkMap[path] : undefined

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 10)
    }

    handleScroll()

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <Popover className="relative z-50 shadow isolate">
      {({ open }) => (
        <>
          <header
            id="navbar"
            className={`fixed top-0 left-0 right-0 z-50 transition-all duration-300 bg-white`}
          >
            <nav className="container px-3 py-2 mx-auto">
              <div className="flex items-center justify-between">
                <Menu isLoggedIn={isLoggedIn} />
                <div className="flex items-center justify-center flex-1">
                  <NextLink href="/">
                    <NextImage
                      src={'/images/logo/tune_logo_burgundy.png'}
                      alt="Logo"
                      height="40"
                      width="120"
                    />
                  </NextLink>
                </div>
                <div
                  className={`text-md font-bold font-scto uppercase ${
                    isScrolled ? 'text-primary' : 'text-primary'
                  }`}
                >
                  {/* {!customCta && ( */}
                  <>
                    {!isLoggedIn ? (
                      <NextLink href="/signup">Sign up</NextLink>
                    ) : (
                      <NextLink href="/account">Profile</NextLink>
                    )}
                  </>
                  {/* )} */}
                  {/* {customCta && customLink && (
                    <NextLink href={customLink}>{customCta}</NextLink>
                  )} */}
                </div>
              </div>
            </nav>
          </header>
          <div
            className={`fixed h-screen w-screen inset-0 bg-black transition-all duration-300 overscroll-contain overflow-clip z-20 ${
              open ? 'backdrop-blur' : 'opacity-0 hidden'
            }`}
          />
        </>
      )}
    </Popover>
  )
}

export default Navbar
